<template>
    <div class="h-100">
        <el-container class="h-100">
            <el-aside width="320px" class="bg-white border-right">
                <el-container class="h-100">
                    <el-header height="auto" class="p-3 border-bottom border-light">组织机构</el-header>
                    <el-main class="p-0 narrow-scrollbar">
                        <el-tree ref="organizationTree" :data="organizationTreeNodes" node-key="value" highlight-current
                            default-expand-all :expand-on-click-node="false"
                            @current-change="selectOrganizationTreeNode" />
                    </el-main>
                </el-container>
            </el-aside>
            <el-main>
                <el-card shadow="never">
                    <div class="row no-gutters">
                        <div class="col">
                            <el-button type="primary" @click="showOrganizationDialog(null)">添加</el-button>
                        </div>
                    </div>
                    <el-table :data="organizations" class="mt-2">
                        <el-table-column prop="regionName" label="行政区划" sortable width="200" />
                        <el-table-column prop="name" label="简称" sortable width="200" />
                        <el-table-column prop="fullName" label="全称" sortable />
                        <el-table-column label="操作" fixed="right" width="100">
                            <template #default="scope">
                                <el-button type="text" @click="showOrganizationDialog(scope.row)">修改</el-button>
                                <el-button type="text" class="text-danger" @click="deleteOrganization(scope.row.id)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-main>
        </el-container>

        <el-dialog :visible.sync="organizationDialogVisible" title="组织机构" :close-on-click-modal="false" width="800px"
            top="0">
            <el-form>
                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="行政区划" class="form-item-required">
                            <el-input :value="organization.regionName" readonly placeholder="行政区划">
                                <template #append>
                                    <el-button @click="selectRegionDialogVisible=true">选择</el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="类型" class="form-item-required">
                            <el-select v-model="organization.type" placeholder="类型">
                                <el-option v-for="organizationType in $store.state.dataDefinitions.organizationTypes"
                                    :key="organizationType.key" :value="organizationType.key"
                                    :label="organizationType.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="简称" class="form-item-required">
                            <el-input v-model="organization.name" placeholder="简称" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="全称" class="form-item-required">
                            <el-input v-model="organization.fullName" placeholder="全称" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="联系电话" class="form-item-required">
                            <el-input v-model="organization.phone" placeholder="联系电话" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地址" class="form-item-required">
                            <el-input v-model="organization.address" placeholder="地址" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="my-2">
                    <div>位置</div>
                    <div class="border rounded overflow-hidden mt-2">
                        <map-picker v-model="organization.lnglat" :center="$store.state.defaultMapCenter" :zoom="12"
                            style="height: 320px;"
                            @change="organization.longitude=$event[0];organization.latitude=$event[1]" />
                        <div class="border-top p-2">
                            <el-row :gutter="8">
                                <el-col :span="12">
                                    <el-input v-model="organization.longitude">
                                        <template #prepend>经度</template>
                                    </el-input>
                                </el-col>
                                <el-col :span="12">
                                    <el-input v-model="organization.latitude">
                                        <template #prepend>纬度</template>
                                    </el-input>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>

                <el-form-item label="排序" class="form-item-required">
                    <el-input-number v-model="organization.sort" controls-position="right" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveOrganization">确定</el-button>
                <el-button @click="organizationDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-region-dialog v-model="selectRegionDialogVisible"
            @select="organization.regionId=$event.id;organization.regionName=$event.name" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                organizationTreeNodes: [],
                organizationTreeNode: null,
                organizations: [],
                organization: {},
                organizationDialogVisible: false,
                selectRegionDialogVisible: false,
            };
        },
        methods: {
            async loadOrganizationTreeNodes() {
                let response = await this.$axios.get('/api/Organization/GetOrganizationTreeNodes');
                this.organizationTreeNodes = response.data;
                if (this.organizationTreeNodes.length > 0) {
                    this.$nextTick(() => {
                        this.$refs.organizationTree.setCurrentKey(this.organizationTreeNodes[0].value);
                        this.selectOrganizationTreeNode(this.organizationTreeNodes[0]);
                    });
                }
            },
            selectOrganizationTreeNode(data) {
                this.organizationTreeNode = data;
                this.loadChildOrganizations();
            },
            async loadChildOrganizations() {
                let response = await this.$axios.get('/api/Organization/GetChildOrganizations', {
                    params: {
                        parentId: this.organizationTreeNode.value,
                    }
                });
                for (const organization of response.data) {
                    if (organization.longitude) {
                        organization.lnglat = [organization.longitude, organization.latitude];
                    }
                }
                this.organizations = response.data;
            },
            showOrganizationDialog(organization) {
                if (organization) {
                    this.organization = JSON.parse(JSON.stringify(organization));
                }
                else {
                    this.organization = { sort: this.getNextNumber(this.organizations.map(p => p.sort)) };
                }
                this.organizationDialogVisible = true;
            },
            getNextNumber(numbers) {
                if (numbers.length == 0) {
                    return 1;
                }

                return Math.max(...numbers) + 1;
            },
            async saveOrganization() {
                if (this.organization.id == undefined) {
                    let response = await this.$axios.post('/api/Organization/AddOrganization', {
                        parentId: this.organizationTreeNode.value,
                        regionId: this.organization.regionId,
                        type: this.organization.type,
                        name: this.organization.name,
                        fullName: this.organization.fullName,
                        phone: this.organization.phone,
                        address: this.organization.address,
                        longitude: this.organization.longitude,
                        latitude: this.organization.latitude,
                        sort: this.organization.sort,
                    });
                    this.organizationDialogVisible = false;
                    this.loadChildOrganizations();

                    let organization = response.data;
                    if (this.organizationTreeNode) {
                        this.organizationTreeNode.children.push({
                            value: organization.id,
                            label: organization.name,
                            tag: organization,
                            children: [],
                        });
                    }
                    else {
                        this.organizationTreeNodes.push({
                            value: organization.id,
                            label: organization.name,
                            tag: organization,
                            children: [],
                        });
                    }
                }
                else {
                    let response = await this.$axios.post('/api/Organization/UpdateOrganization', {
                        id: this.organization.id,
                        regionId: this.organization.regionId,
                        type: this.organization.type,
                        name: this.organization.name,
                        fullName: this.organization.fullName,
                        phone: this.organization.phone,
                        address: this.organization.address,
                        longitude: this.organization.longitude,
                        latitude: this.organization.latitude,
                        sort: this.organization.sort,
                    });
                    this.organizationDialogVisible = false;
                    this.loadChildOrganizations();

                    let organization = response.data;
                    let organizationTreeNode = this.organizationTreeNode.children.filter(p => p.value == organization.id)[0];
                    organizationTreeNode.label = organization.name;
                    organizationTreeNode.tag = organization;
                }
            },
            async deleteOrganization(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Organization/DeleteOrganization', { id: id });
                this.loadChildOrganizations();

                let organizationTreeNode = this.organizationTreeNode.children.filter(p => p.id == id)[0];
                let index = this.organizationTreeNode.children.indexOf(organizationTreeNode);
                this.organizationTreeNode.children.splice(index, 1);
            },
        },
        created() {
            this.loadOrganizationTreeNodes();
        },
    };
</script>